<script>
    import Jumbo from './Jumbo.svelte'
    let accentColor = 'rgb(44, 199, 44)'
    let baseColor = 'rgb(177, 0, 0)'
    let brand = 'G3N15'
    let slogan = 'Master the Basics'
</script>

<Jumbo {brand} {slogan} {accentColor} {baseColor} />

<style>
    :global(body) {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(-45deg, black, #001600, #144118);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
    }

    @keyframes -global-gradient {
        0% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }

    :global(@font-face) {
        font-family: 'Unique';
        src: url('/fonts/deserta.otf') format('opentype');
    }

    :global(@font-face) {
        font-family: 'Base';
        src: url('/fonts/Molot.otf') format('opentype');
    }
</style>
