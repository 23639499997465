<script>
    export let brand
    export let slogan
    export let accentColor = 'white'
    export let baseColor = 'black'
</script>

<div style="--accent-color: {accentColor}; --base-color: {baseColor}">
    <h1>{brand}</h1>
    {#if slogan}
        <h3>
            {slogan}
        </h3>
    {/if}
</div>

<style>
    h1 {
        font-family: 'Unique', serif;
        font-size: min(25vw, 256px);
        font-weight: lighter;
        color: var(--accent-color);
        margin: 0;
        text-align: center;
        animation: letters-glow-animation 5s ease-in-out infinite;
        transition: 1s ease-in-out;
    }

    @keyframes letters-glow-animation {
        0% {
            filter: brightness(2) drop-shadow(0 0 5px);
        }
        50% {
            filter: brightness(2) drop-shadow(0 0 20px);
        }
        100% {
            filter: brightness(2) drop-shadow(0 0 5px);
        }
    }

    h1:hover,
    h1:active {
        filter: brightness(2) drop-shadow(0 0 30px);
    }
    h3 {
        font-family: 'Base', sans-serif;
        font-size: min(10vw, 64px);
        color: var(--base-color);
        transition: 1s ease-in-out;
        margin: 0;
        text-align: center;
        transition: 1s ease-in-out;
    }
    h3:hover,
    h3:active {
        filter: brightness(2) drop-shadow(0 10px 100px);
    }
</style>
